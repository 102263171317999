import { Snackbar, Alert, AlertTitle } from '@mui/material';
import React, { useState } from 'react';

interface AlertMessageProps {
    message: string;
    onClose: () => void;
    alertSeverity: 'error' | 'warning' | 'info' | 'success';
}

const AlertMessage: React.FC<AlertMessageProps> = ({ message, onClose, alertSeverity = 'error' }) => {
    const [open, setOpen] = useState(true);

    const handleClose = () => {
        setOpen(false);
        onClose();
    };

    return (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={alertSeverity}>
                <AlertTitle>{alertSeverity.charAt(0).toUpperCase() + alertSeverity.slice(1)}</AlertTitle>
                {message}
            </Alert>
        </Snackbar>
    );
};

export default AlertMessage;
