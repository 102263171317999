import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { History } from '@mui/icons-material';

const BackButton = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate('/');
    }
  };

  return (
    <Button 
    title="Go Back"
    variant="contained" 
    onClick={handleGoBack}
    startIcon={<History />}
    >
      Back
    </Button>
  );
};

export default BackButton;
