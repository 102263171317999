import React, { useState, useEffect, ChangeEvent } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Button, Box, Paper, Table, TableRow, TableCell, TableBody, TableHead, TextField, Autocomplete, Typography, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import AnalyzedItem from '../interfaces/AnalyzedItem';
import BackButton from './BackButton';
import { Add, Save, LinkedIn, Facebook, Twitter } from '@mui/icons-material';
import AlertMessage from './AlertMessage';
import { AlertSeverity } from '../types/AlertSeverity';

// todo: store as environment variables inside of Azure for security
const getAnalyzedItemByIdEndpoint = 'https://aicontentanalyzer2.azurewebsites.net/api/analyzeditems?code=4D9O1TISrlGM9CSC0OcHtcT6q50PYg-woc6xhrGVyxODAzFuY51njw==&id=';
const updateAnalyzedItemEndpoint = 'https://aicontentanalyzer2.azurewebsites.net/api/UpdateAnalyzedItem?code=twjS5CCcbJNHPvy_wTOVkqiNqX9Y28zB2hf_5J7B-F31AzFuxWAQ6g==';

interface EditPageProps {
    specialties: string[],
    specialtyTopicsMap: { [key: string]: string[] }
}

// todo: store in DB
const categoryOptions: string[] = [
    'From the Journals',
    'Clinical Guidelines',
    'Commentary & Perspectives',
    'Conference News',
    'FDA & Government News',
    'Business Management',
    // 'News',
    // 'Features'
];

// todo: make helper functions file
const generateAbsoluteUrl = (item: AnalyzedItem): string => {
    return `https://${item.Domain}${item.URL}`;
};

const EditPage: React.FC<EditPageProps> = ({ specialties, specialtyTopicsMap }) => {
    const { id } = useParams();
    const [analyzedItem, setAnalyzedItem] = useState<AnalyzedItem | null>(null);
    const [summary, setSummary] = useState<string>('');
    const [teaser, setTeaser] = useState<string>('');
    const [primarySpecialty, setPrimarySpecialty] = useState<string>('');
    const [selectedSpecialties, setSelectedSpecialties] = useState<string[]>([]);
    const [topicOptions, setTopicOptions] = useState<string[]>([]);
    const [selectedTopics, setSelectedTopics] = useState<string[]>([]);
    const [categories, setCategories] = useState<string[]>([]);
    const [selectedKeywords, setSelectedKeywords] = useState<string[]>([]);
    const [keywords, setKeywords] = useState<string[]>([]);
    const [keywordsInputValue, setKeywordsInputValue] = useState<string>('');
    const [linkedIn, setLinkedIn] = useState<string>('');
    const [facebook, setFacebook] = useState<string>('');
    const [twitter, setTwitter] = useState<string>('');
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<string>('');
    const [alertSeverity, setAlertSeverity] = useState<AlertSeverity>('error');
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [openConfirmSaveDialog, setOpenConfirmSaveDialog] = useState<boolean>(false);
    const [isConexiant, setIsConexiant] = useState<boolean>(false);

    useEffect(() => {
        (async () => {
            try {
                const response = await axios.get(`${getAnalyzedItemByIdEndpoint}${id}`);
                if (response.status === 200) {
                    const data: AnalyzedItem = response.data;
                    setAnalyzedItem(data);
                    setIsConexiant(data.Domain === "conexiant.com");
                    setTeaser(data.Results.Teaser!);
                    setSummary(data.Results.Summary!);
                    setPrimarySpecialty(data.Results.PrimarySpecialty!);
                    setSelectedSpecialties(data.Results.Specialties!);
                    setSelectedTopics(data.Results.TopicsConditions!);
                    let topics: string[] = [];
                    data.Results.Specialties!.forEach((specialty: string) => {
                        topics = topics.concat(specialtyTopicsMap[specialty] || []);
                    });
                    // Remove duplicate topics and sort alphabetically
                    const uniqueTopics = Array.from(new Set(topics)).sort();
                    setTopicOptions(uniqueTopics!);
                    setCategories(data.Results.Categories!);
                    setSelectedKeywords(data.Results.Keywords!);
                    setKeywords(data.Results.Keywords!);
                    setLinkedIn(data.Results.SocialPosts!.LinkedIn);
                    setFacebook(data.Results.SocialPosts!.Facebook);
                    setTwitter(data.Results.SocialPosts!.Twitter);
                }
                else {
                    console.error('Failed to fetch analyzed item:', response.statusText);
                }
            }
            catch (error: any) {
                console.error('Error fetching analyzed item:', error);
            }
        })();
    }, [id, specialtyTopicsMap]);

    const handleCloseAlert = () => {
        setShowAlert(false);
    };

    const handleConfirmSaveDialogOpen = () => {
        setOpenConfirmSaveDialog(true);
    };

    const handleConfirmSaveDialogClose = () => {
        setOpenConfirmSaveDialog(false);
    };

    // basic form validation prior to saving with specific error messages; can refactor to clean it up further
    const handleSaveClick = async () => {
        if (isConexiant) {
            setShowAlert(true);
            setAlertSeverity('warning');
            setAlertMessage("Please be aware updating Conexiant.com content items inside Content Boost is limited. Primary Specialty, Specialties, Topics & Conditions, and Categories must be changed inside of Umbraco.");
        }
        else {
            if (primarySpecialty === '') {
                setShowAlert(true);
                setAlertSeverity('error');
                setAlertMessage("Please select a primary specialty prior to saving.");
                return;
            }

            // this case should never occur due to rule above with primary specialty
            if (specialties.length < 1) {
                setShowAlert(true);
                setAlertSeverity('error');
                setAlertMessage("Please select at least one specialty prior to saving.");
                return;
            }

            if (selectedTopics.length < 1) {
                setShowAlert(true);
                setAlertSeverity('error');
                setAlertMessage("Please select at least one topic or condition prior to saving.");
                return;
            }

            if (categories.length < 1) {
                setShowAlert(true);
                setAlertSeverity('error');
                setAlertMessage("Please select at least one category prior to saving.");
                return;
            }
        }

        handleConfirmSaveDialogOpen();
    };

    const handleConfirmSave = async () => {
        handleConfirmSaveDialogClose();

        setIsSaving(true); // basic way for user to prevent spamming save - might not be needed after adding dialog/modal

        if (!analyzedItem) {
            setShowAlert(true);
            setAlertSeverity('error');
            setAlertMessage("Fatal issue saving analyzed item; record not found. Please contact an administrator.");
            setIsSaving(false);
            return;
        }

        let updatedAnalyzedItem: AnalyzedItem;

        // if it's a conexiant item - we only allow a subset of the fields to be changed from the orginal
        if (isConexiant) {
            updatedAnalyzedItem = {
                ...analyzedItem,
                Results: {
                    ...analyzedItem.Results,
                    Teaser: teaser,
                    Summary: summary,
                    Keywords: selectedKeywords,
                    SocialPosts: {
                        LinkedIn: linkedIn,
                        Facebook: facebook,
                        Twitter: twitter
                    },
                }
            };
        }
        else {
            // validate primarySpecialty rules prior to saving:
            // - primarySpecialty is in the list of selectedSpecialties
            // - primarySpecialty is in the first position [0] of the selectedSpecialties list/array

            // If primarySpecialty is not included in selectedSpecialties, append it to the beginning
            if (!selectedSpecialties.includes(primarySpecialty)) {
                setSelectedSpecialties([primarySpecialty, ...selectedSpecialties]);
            }
            // If selected specialties does include the primary, make sure it is in first position
            else if (selectedSpecialties.includes(primarySpecialty) && selectedSpecialties[0] !== primarySpecialty) {
                const updatedSelectedSpecialties = [primarySpecialty, ...selectedSpecialties.filter(item => item !== primarySpecialty)];
                setSelectedSpecialties(updatedSelectedSpecialties);
            }

            updatedAnalyzedItem = {
                ...analyzedItem,
                Results: {
                    ...analyzedItem.Results,
                    Teaser: teaser,
                    Summary: summary,
                    PrimarySpecialty: primarySpecialty,
                    Specialties: selectedSpecialties,
                    TopicsConditions: selectedTopics,
                    Categories: categories,
                    Keywords: selectedKeywords,
                    SocialPosts: {
                        LinkedIn: linkedIn,
                        Facebook: facebook,
                        Twitter: twitter
                    },
                }
            };
        }

        // console.log("isConexiant?=" + isConexiant);
        // console.log('Saving item with ID =' + analyzedItem._id);
        // console.log("Original Analyzed Item:");
        // console.log(analyzedItem);
        // console.log("Updated Analyzed Item:");
        // console.log(updatedAnalyzedItem);
        //setIsSaving(false);
        try {
            const updateAnalyzedItemResponse = await axios.post(updateAnalyzedItemEndpoint, updatedAnalyzedItem);

            if (updateAnalyzedItemResponse.status === 200) {
                setShowAlert(true);
                setAlertSeverity('success');
                setAlertMessage("Item updated successfully!");
            }
            else {
                setShowAlert(true);
                setAlertSeverity('error');
                setAlertMessage("Error updating item. Unexpected response status.");
            }
        }
        catch (error: any) {
            setShowAlert(true);
            setAlertSeverity('error');
            setAlertMessage("Error updating item. Please contact an administrator.");
        }
        finally {
            setIsSaving(false);
        }

    };

    const handlePrimarySpecialtyChange = (event: React.ChangeEvent<{}>, selectedPrimarySpecialty: string | null) => {
        const previousValue: string = primarySpecialty;
        // console.log("handlePrimarySpecialtyChange called at " + new Date().toISOString());
        // console.log("Old Primary Specialty: " + previousValue);
        // console.log("New Primary Specialty: " + selectedPrimarySpecialty);

        // a primary specialty was selected and it isn't null/empty
        if (selectedPrimarySpecialty) {
            //  update the primary specialty
            setPrimarySpecialty(selectedPrimarySpecialty);

            // If the primary specialty isn't in the list of currently selected specialties:
            // * add it to the front of the list
            // * updated the selected specialties
            // * trigger change event logic for that field (e.g. to fire logic to populate topics options below)
            if (!selectedSpecialties.includes(selectedPrimarySpecialty)) {
                const updatedSelectedSpecialties: string[] = [selectedPrimarySpecialty, ...selectedSpecialties];
                setSelectedSpecialties(updatedSelectedSpecialties);
                handleSpecialtyChange(event, updatedSelectedSpecialties);
            }
            else {
                const updatedSelectedSpecialties: string[] = [selectedPrimarySpecialty, ...selectedSpecialties.filter(specialty => specialty !== selectedPrimarySpecialty)];
                setSelectedSpecialties(updatedSelectedSpecialties);
                handleSpecialtyChange(event, updatedSelectedSpecialties);
            }
        }
        else {
            console.log("A primary specialty was not selected");
            if (selectedSpecialties.includes(previousValue)) {
                const updatedSelectedSpecialties = selectedSpecialties.filter(specialty => specialty !== previousValue);
                setSelectedSpecialties(updatedSelectedSpecialties);
                handleSpecialtyChange(event, updatedSelectedSpecialties);
            }

            setPrimarySpecialty('');
        }
    };

    const handleSpecialtyChange = (event: React.ChangeEvent<{}>, newSelectedSpecialties: string[]) => {
        let topics: string[] = [];
        newSelectedSpecialties.forEach(specialty => {
            topics = topics.concat(specialtyTopicsMap[specialty] || []);
        });

        // Remove duplicate topics and sort alphabetically
        const uniqueTopics = Array.from(new Set(topics)).sort();
        setTopicOptions(uniqueTopics);
        setSelectedSpecialties(newSelectedSpecialties);

        // Remove selected topics that are no longer in the list of options
        setSelectedTopics(prevSelectedTopics => prevSelectedTopics.filter(topic => uniqueTopics.includes(topic)));

        // Check if the primary specialty is no longer in the selected specialties
        // if (!newSelectedSpecialties.includes(primarySpecialty)) {
        //     // Set primary specialty to empty string
        //     setPrimarySpecialty('');
        // }
    };

    const handleTopicsChange = (event: React.ChangeEvent<{}>, selectedTopics: string[]) => {
        console.log("handleTopicsChange called at " + new Date().toISOString());
        setSelectedTopics(selectedTopics);
    };

    const handleCategoriesChange = (event: React.ChangeEvent<{}>, selectedCategories: string[]) => {
        setCategories(selectedCategories);
    };

    const handleSummaryChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { target: { value } } = event;
        setSummary(value);
    };

    const handleTeaserChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { target: { value } } = event;
        setTeaser(value);
    };

    // handle adding a brand new keyword while editing
    const handleAddKeyword = () => {
        if (keywordsInputValue.trim() !== '' && !selectedKeywords.includes(keywordsInputValue)) {
            setSelectedKeywords([...selectedKeywords, keywordsInputValue]);
            setKeywords([...keywords, keywordsInputValue]);
            setKeywordsInputValue('');
        }
    };

    // handle change events from the keywords autocomplete
    const handleKeywordsChange = (event: any, newValue: string | string[]) => {
        const newKeywords = Array.isArray(newValue) ? newValue : [newValue];
        setKeywords(newKeywords);
    };

    // handle input value change in the keywords autocomplete
    const handleKeywordsInputValueChange = (event: React.ChangeEvent<{}>, newInputValue: string) => {
        setKeywordsInputValue(newInputValue);
    };

    const handleLinkedInChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { target: { value } } = event;
        setLinkedIn(value);
    };

    const handleFacebookChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { target: { value } } = event;
        setFacebook(value);
    };

    const handleTwitterChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { target: { value } } = event;
        setTwitter(value);
    };

    if (!analyzedItem) {
        return <div>Loading Item with ID = {id}...</div>;
    }

    return (
        <>
            <Box p={2}>
                <Box display="flex" justifyContent="space-between">
                    <BackButton />
                </Box>
                <Paper elevation={3} style={{ marginTop: 16 }}>
                    <Table className="bold-first-cell">
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={2}><strong>Edit Analyzed Item</strong></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>Date Analyzed</TableCell>
                                <TableCell>{new Date(analyzedItem.DateAdded).toLocaleString()}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Title</TableCell>
                                <TableCell>{analyzedItem.Title}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>URL</TableCell>
                                <TableCell><a href={generateAbsoluteUrl(analyzedItem)} target="_blank" rel="noopener noreferrer">{generateAbsoluteUrl(analyzedItem)}</a></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Teaser</TableCell>
                                <TableCell>
                                    <TextField
                                        name="teaser"
                                        multiline
                                        fullWidth
                                        value={teaser}
                                        onChange={handleTeaserChange}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Summary</TableCell>
                                <TableCell>
                                    <TextField
                                        name="summary"
                                        multiline
                                        fullWidth
                                        value={summary}
                                        onChange={handleSummaryChange}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Primary Specialty</TableCell>
                                <TableCell>
                                    <Autocomplete
                                        id="primary-specialty-autocomplete"
                                        options={specialties}
                                        value={primarySpecialty}
                                        onChange={handlePrimarySpecialtyChange}
                                        fullWidth
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Primary Specialty"
                                                variant="outlined"
                                                required
                                            />
                                        )}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Specialties</TableCell>
                                <TableCell>
                                    <Autocomplete
                                        id="specialty-autocomplete"
                                        options={specialties}
                                        value={selectedSpecialties}
                                        onChange={handleSpecialtyChange}
                                        multiple
                                        fullWidth
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Specialties"
                                                variant="outlined"
                                                required
                                            />
                                        )}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Topics & Conditions</TableCell>
                                <TableCell>
                                    <Autocomplete
                                        id="topics-autocomplete"
                                        options={topicOptions}
                                        value={selectedTopics}
                                        onChange={handleTopicsChange}
                                        multiple
                                        fullWidth
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Topics & Conditions"
                                                variant="outlined"
                                                required
                                            />
                                        )}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Categories</TableCell>
                                <TableCell>
                                    <Autocomplete
                                        multiple
                                        options={categoryOptions}
                                        value={categories}
                                        onChange={handleCategoriesChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                name="categories"
                                                variant="outlined"
                                                label="Categories"
                                                placeholder="Select categories"
                                                required
                                            />
                                        )}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Keywords</TableCell>
                                <TableCell>
                                    <Autocomplete
                                        multiple
                                        options={selectedKeywords}
                                        value={keywords}
                                        inputValue={keywordsInputValue}
                                        onInputChange={handleKeywordsInputValueChange}
                                        onChange={handleKeywordsChange}
                                        noOptionsText={keywordsInputValue.trim() !== '' ? `Press enter to add  "${keywordsInputValue}"...` : ''}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <>
                                                            {params.InputProps.endAdornment}
                                                            <IconButton
                                                                onClick={handleAddKeyword}
                                                            >
                                                                <Add />
                                                            </IconButton>
                                                        </>
                                                    ),
                                                    onKeyDown: (e) => {
                                                        if (e.key === 'Enter') {
                                                            handleAddKeyword();
                                                        }
                                                    }
                                                }}
                                            />
                                        )}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Social Post</TableCell>
                                <TableCell>
                                    <Typography variant="body2" sx={{ fontWeight: 700 }}><LinkedIn sx={{ verticalAlign: "middle" }} /> LinkedIn</Typography>
                                    <TextField
                                        fullWidth
                                        multiline
                                        value={linkedIn}
                                        onChange={handleLinkedInChange}
                                        margin="dense"
                                    />
                                    <Typography variant="body2" sx={{ fontWeight: 700 }}><Facebook sx={{ verticalAlign: "middle" }} /> Facebook</Typography>
                                    <TextField
                                        fullWidth
                                        multiline
                                        value={facebook}
                                        onChange={handleFacebookChange}
                                        margin="dense"
                                    />
                                    <Typography variant="body2" sx={{ fontWeight: 700 }}><Twitter sx={{ verticalAlign: "middle" }} /> Twitter</Typography>
                                    <TextField
                                        fullWidth
                                        multiline
                                        value={twitter}
                                        onChange={handleTwitterChange}
                                        margin="dense"
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={2}>
                                    <Button
                                        variant="contained"
                                        onClick={handleSaveClick}
                                        startIcon={<Save />}
                                        disabled={isSaving}
                                    >
                                        {isSaving ? 'Saving...' : 'Save'}
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Paper>
                {showAlert && <AlertMessage message={alertMessage} onClose={handleCloseAlert} alertSeverity={alertSeverity} />}
            </Box>
            <Dialog
                open={openConfirmSaveDialog}
                onClose={handleConfirmSaveDialogClose}
                aria-labelledby="confirm-save-dialog-title"
                aria-describedby="confirm-save-dialog-description"
            >
                <DialogTitle id="confirm-save-dialog-title">Confirm Updates</DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirm-save-dialog-description">
                        Are you sure you want to save & update this item?<br />
                        This will overwrite the current item and cannot be reverted back.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmSaveDialogClose}>Cancel</Button>
                    <Button onClick={handleConfirmSave} autoFocus>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default EditPage;
