import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Button, Box, Table, TableBody, TableCell, TableRow, Paper, TableHead } from '@mui/material';
import AnalyzedItem from '../interfaces/AnalyzedItem';
import BackButton from './BackButton';
import './ViewPage.css';
// import QueueForReProcessing from './QueueForReProcessing';
import CSVList from './CSVList';
import { Edit } from '@mui/icons-material';
import SocialPostComponent from './SocialPostComponent';
import PipeSeparatedList from './PipeSeparatedList';

const ViewPage: React.FC = () => {
  const { id } = useParams();
  const [analyzedItem, setAnalyzedItem] = useState<AnalyzedItem | null>(null);
  const navigate = useNavigate();

  const getAnalyzedItemByIdEndpoint = 'https://aicontentanalyzer2.azurewebsites.net/api/analyzeditems?code=4D9O1TISrlGM9CSC0OcHtcT6q50PYg-woc6xhrGVyxODAzFuY51njw==&id=';

  const generateAbsoluteUrl = (item: AnalyzedItem): string => {
    return `https://${item.Domain}${item.URL}`;
  };

  const handleEditClick = () => {
    navigate(`/edit/${id}`);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${getAnalyzedItemByIdEndpoint}${id}`);
        const data = response.data;

        if (response.status === 200) {
          setAnalyzedItem(data);
        } else {
          console.error('Failed to fetch analyzed item:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching analyzed item:', error);
      }
    };

    fetchData();
  }, [id]);

  if (!analyzedItem) {
    return <div>Loading Item with ID = {id}...</div>;
  }

  return (
    <Box p={2}>
      <Box display="flex" justifyContent="space-between">
        <BackButton />
        {/* <QueueForReProcessing url={generateAbsoluteUrl(analyzedItem)} /> */}
      </Box>
      <Paper elevation={3} style={{ marginTop: 16 }}>
        <Table className="bold-first-cell">
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}><strong>View Analyzed Item</strong></TableCell>
              </TableRow>
          </TableHead>
          <TableBody>
          <TableRow>
              <TableCell>Date Analyzed</TableCell>
              <TableCell>{new Date(analyzedItem.DateAdded).toLocaleString()}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>{analyzedItem.Title}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>URL</TableCell>
              <TableCell><a href={generateAbsoluteUrl(analyzedItem)} target="_blank" rel="noopener noreferrer">{generateAbsoluteUrl(analyzedItem)}</a></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Teaser</TableCell>
              <TableCell>{analyzedItem.Results.Teaser}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Summary</TableCell>
              <TableCell>{analyzedItem.Results.Summary}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Primary Specialty</TableCell>
              <TableCell>{analyzedItem.Results.PrimarySpecialty}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Specialties</TableCell>
              <TableCell>
                <CSVList csvString={analyzedItem.Results.Specialties?.join(', ')} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Topics & Conditions</TableCell>
              <TableCell>
                <PipeSeparatedList pipeSeparatedString={analyzedItem.Results.TopicsConditions?.join('|')} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Categories</TableCell>
              <TableCell>
                <CSVList csvString={analyzedItem.Results.Categories?.join(', ')} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Keywords</TableCell>
              <TableCell>
                <CSVList csvString={analyzedItem.Results.Keywords?.join(', ')} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Social Post</TableCell>
              <TableCell><SocialPostComponent socialPosts={analyzedItem.Results.SocialPosts} /></TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}>
                <Button
                  variant="contained"
                  onClick={(e) => handleEditClick()}
                  startIcon={<Edit />}
                >
                  Edit Results
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    </Box>
  );
};

export default ViewPage;