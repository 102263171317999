import React from 'react';

interface CSVListProps {
    csvString?: string;
}

const CSVList: React.FC<CSVListProps> = ({ csvString = '' }) => {
    if (csvString === '') {
        return null;
    }
    const items = csvString.split(',');

    return (
        <ul>
            {items.map((item, index) => (
                <li key={index}>{item.trim()}</li>
            ))}
        </ul>
    );
};

export default CSVList;
