import React from 'react';
import { Box, Chip } from '@mui/material';
import { DataGridPro, GridActionsCellItem, GridColDef, GridRenderCellParams, GridRowParams, GridToolbar } from '@mui/x-data-grid-pro';
import { Visibility } from '@mui/icons-material';
import AnalyzedItem from '../interfaces/AnalyzedItem';
import './ListingPage.css';

interface ListingPageProps {
  analyzedItems: AnalyzedItem[];
}

const generateAbsoluteUrl = (item: AnalyzedItem): string => {
  return `https://${item.Domain}${item.URL}`;
};

const ListingPage: React.FC<ListingPageProps> = ({ analyzedItems }) => {
  const handleViewClick = (row: AnalyzedItem) => {
    const id = row._id;
    const url = `/view/${id}`;
    window.open(url, "_blank");
  };

  // columns should keep the same reference between 2 renders; they aren't designed to change once the component mounts
  // todo: memoize column defs
  const columns: GridColDef[] = [
    {
      field: '_id',
      filterable: false
    },
    {
      field: 'DateAdded',
      headerName: 'Date',
      filterable: false,
      disableReorder: true,
      type: 'string',
      width: 110,
      valueGetter: (value) => new Date(value as string).toLocaleString(),
      valueFormatter: (value) => new Date(value as string).toLocaleString()
    },
    {
      field: 'Domain',
      headerName: 'Domain',
      disableReorder: true,
      flex: 0.75,
      type: 'string'
    },
    {
      field: 'URL',
      headerName: 'URL',
      disableReorder: true,
      filterable: false,
      flex: 1,
      type: 'string',
      renderCell: (params: GridRenderCellParams) => {
        return <a href={generateAbsoluteUrl(params.row)} target="_blank" rel="noopener noreferrer">{params.value as string}</a>
      }
    },
    {

      field: 'Title',
      headerName: 'Title',
      disableReorder: true,
      sortable: false,
      filterable: false,
      flex: 1,
      type: 'string'
    },
    {
      field: 'PrimarySpecialty',
      headerName: 'Primary Specialty',
      disableReorder: true,
      sortable: false,
      filterable: true,
      flex: 0.5,
      type: 'string',
      valueGetter: (value: string, row: AnalyzedItem) => row.Results?.PrimarySpecialty,
      renderCell: (params: GridRenderCellParams) => {
        const primarySpecialty: string = params.row.Results?.PrimarySpecialty;
        if(!primarySpecialty) {
          return null;
        }
        return <Chip label={primarySpecialty} color="primary" size="small" />
      }
    },
    {
      field: 'Specialties',
      headerName: 'Specialties',
      filterable: false,
      disableReorder: true,
      sortable: false,
      flex: 0.5,
      valueGetter: (value: string[], row: AnalyzedItem) => row.Results.Specialties?.join('\n'),
      renderCell: (params: GridRenderCellParams<AnalyzedItem>) => {
        const specialties: string[] = params.value?.split('\n') || [];
        if (specialties.length === 1 && specialties[0] === '') {
          return null;
        }
        return (
          <div style={{ display: 'flex', flexDirection: 'column', padding: '0.5rem 0' }}>
            {specialties.map((specialty: string, index: number) => (
              <Chip key={index} label={specialty} color="default" size="small" sx={{ width: 'fit-content', marginBottom: index !== specialties.length - 1 ? '0.5rem' : 0 }} />
            ))}
          </div>
        );
      },
    },
    {
      field: 'Keywords',
      headerName: 'Keywords',
      filterable: true,
      valueGetter: (value: string[], row: AnalyzedItem) => row.Results.Keywords?.join('\n'),
    },
    {
      field: 'Topics',
      headerName: 'Topics',
      filterable: true,
      valueGetter: (value: string[], row: AnalyzedItem) => row.Results.TopicsConditions?.join('\n'),
    },
    {
      field: 'Actions',
      type: 'actions',
      headerName: 'Actions',
      sortable: false,
      filterable: false,
      disableReorder: true,
      flex: 0.25,
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem title="View" label="View" icon={<Visibility />} onClick={() => handleViewClick(params.row)} />,
      ],
    },
  ];

  return (
    <Box>
      <DataGridPro
        columnVisibilityModel={{
          _id: false,
          Keywords: false,
          Topics: false
        }}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 10, page: 0 },
          },
        }}
        rows={analyzedItems}
        columns={columns}
        pagination
        disableRowSelectionOnClick
        getRowId={(row) => row._id}
        autoHeight
        getRowHeight={() => 'auto'}
        disableColumnMenu
        disableDensitySelector
        disableColumnSelector
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            printOptions: { disableToolbarButton: true },
            csvOptions: { disableToolbarButton: true },
            showQuickFilter: true,
          },
        }}
        pageSizeOptions={[10, 25, 50, 100]}
      />
    </Box>
  );
};

export default ListingPage;