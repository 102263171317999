import React from 'react';

interface PipeSeparatedListProps {
    pipeSeparatedString?: string;
}

const PipeSeparatedList: React.FC<PipeSeparatedListProps> = ({ pipeSeparatedString = '' }) => {
    if (pipeSeparatedString === '') {
        return null;
    }
    const items = pipeSeparatedString.split('|'); // Split by pipe character

    return (
        <ul>
            {items.map((item, index) => (
                <li key={index}>{item.trim()}</li>
            ))}
        </ul>
    );
};

export default PipeSeparatedList;
