import React from 'react';
import { Button, TextField, Typography, Snackbar, Container, Box, CircularProgress } from '@mui/material';
import axios, { AxiosError } from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { Client } from '@passwordlessdev/passwordless-client';
import { PASSWORDLESS_API_KEY, PASSWORDLESS_API_URL, CREATE_TOKEN_ENDPOINT } from '../config/PasswordlessOptions';
import { isEmailValid, isEmailWhiteListed } from '../config/WhitelistedEmails';

const passwordlessClient: Client = new Client({
    apiKey: PASSWORDLESS_API_KEY,
    apiUrl: PASSWORDLESS_API_URL
});

const RegisterPage: React.FC = () => {
    const navigate = useNavigate();
    const [email, setEmail] = React.useState<string>('');
    const [displayName, setDisplayName] = React.useState<string>('');
    
    const [errMsg, setErrMsg] = React.useState<string>('');
    const [openSnackbar, setOpenSnackbar] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);

    const handleOnSubmitClick = async (e: any) => {
        e.preventDefault();

        if(!email) {
            setErrMsg(`Error: Please enter your email address prior to registering`);
            return false;
        }

        if(!isEmailValid(email)) {
            setErrMsg(`Error: Please enter a valid email address prior to registering`);
            return false;
        }

        if(!displayName) {
            setErrMsg(`Error: Please enter your display name prior to registering`);
            return false;
        }

        // make sure email on whitelist before proceeding
        if(!isEmailWhiteListed(email)) {
            console.log(`Error: Email address "${email}" is not able to register for Content Boost. Please contact an administrator if you believe this is a mistake.`);
            setErrMsg(`Error: Provided email address "${email}" is not able to register for Content Boost. Please contact an administrator if you believe this is a mistake.`);
            return false;
        }

        setLoading(true);

        const registerTokenParams = {
            alias: email,
            displayName: displayName
        };

        try {
            const registerTokenResponse = await axios.get(
                CREATE_TOKEN_ENDPOINT,
                {
                    params: registerTokenParams
                }
            );

            // alias already in use
            if(registerTokenResponse.status === 409) {
                setErrMsg(`The email address you entered ${email} is already in use by another userId. Please contact an administrator if this is a mistake.`);
                return false;
            }   

            if (registerTokenResponse.status !== 200) {
                setErrMsg('Registration token fetching failed!');
                return false;
            }

            // Register the token with the end-user's device & email address
            const { token, error } = await passwordlessClient.register(registerTokenResponse.data.token, email);
            if (token) {
                setErrMsg('Registration Successful!');
                setLoading(false);
                setOpenSnackbar(true);
                return navigate(`/?email=${encodeURIComponent(email)}`);
            } else {
                setErrMsg('Registration Failed!');
                setLoading(false);
                setOpenSnackbar(true);
                console.error(error);
            }
        } catch (error: any) {
            if (axios.isAxiosError(error)) {
                const axiosError = error as AxiosError;
                setErrMsg(axiosError.message);
            } else {
                setErrMsg('An error occurred while processing your registration request.');
            }
            setLoading(false);
            setOpenSnackbar(true);
            return null;
        }
    };

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    return (
        <Container component="main" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
            <Box sx={{ width: '100%', maxWidth: '400px' }}>
                <form>
                    <Typography variant="h5" align="center" gutterBottom>Register for Content Boost</Typography>
                    <TextField
                        label="Email"
                        name="email"
                        value={email}
                        required
                        onChange={(e) => setEmail(e.target.value)}
                        fullWidth
                        margin="normal"
                        helperText="User's email address (e.g. john.smith@broadcastmed.com)"
                    />
                    <TextField
                        label="Display Name"
                        name="displayName"
                        value={displayName}
                        required
                        onChange={(e) => setDisplayName(e.target.value)}
                        fullWidth
                        margin="normal"
                        helperText="Display name for account in app (e.g. John Smith)"
                    />

                    {errMsg && <Typography color="error">{errMsg}</Typography>}
                    {
                        loading ?
                            (
                                <CircularProgress size={24} />
                            )
                            : (
                                <Button
                                    variant="contained"
                                    onClick={handleOnSubmitClick}
                                >
                                    Register
                                </Button>
                            )
                    }
                    <Snackbar
                        open={openSnackbar}
                        autoHideDuration={6000}
                        onClose={handleSnackbarClose}
                        message={errMsg}
                    />
                </form>
                <Typography variant="body2" align="center" mt={2}>
                    Already have an account? <Link to="/">Login Now</Link>
                </Typography>
            </Box>
        </Container>
    );
};

export default RegisterPage;