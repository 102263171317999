export const WHITELISTED_EMAILS: string[] = [
    "kerri.miller@broadcastmed.com",
    "teraya.smith@broadcastmed.com",
    "elizabeth.janetschek@broadcastmed.com",
    "michael.ripa@broadcastmed.com",
    "amy.pfeiffer@broadcastmed.com",
    "mcurylo@broadcastmed.com",
    "frank.buchner@broadcastmed.com",
    "christopher.kobel@broadcastmed.com",
    "jobrien@broadcastmed.com",
    "lee.schweizer@broadcastmed.com",
    "callie.houlihan@broadcastmed.com",
    "zachary.mitchell@broadcastmed.com",
    "daniel.wolf@broadcastmed.com",
    "stephen.scoza@broadcastmed.com",
    "sarah.fackler@broadcastmed.com",
    "cara.glynn@broadcastmed.com",
    "kerri.miller@conexiant.com",
    "teraya.smith@conexiant.com",
    "elizabeth.janetschek@conexiant.com",
    "michael.ripa@conexiant.com",
    "amy.pfeiffer@conexiant.com",
    "matt.curylo@conexiant.com",
    "frank.buchner@conexiant.com",
    "christopher.kobel@conexiant.com",
    "justin.obrien@conexiant.com",
    "lee.schweizer@conexiant.com",
    "callie.houlihan@conexiant.com",
    "zachary.mitchell@conexiant.com",
    "daniel.wolf@conexiant.com",
    "stephen.scoza@conexiant.com",
    "sarah.fackler@conexiant.com",
    "cara.glynn@conexiant.com",
    "james.gallagher@conexiant.com",
    "deborah.fisher@conexiant.com",
    "jennifer.kirby@conexiant.com",
    "julie.greenbaum@conexiant.com",
    "kerri.decindis@conexiant.com",
    "rene.luthe@conexiant.com",
    "veronica.daub@conexiant.com",
    "jennifer.ford@conexiant.com"
];

export const isEmailWhiteListed = (email: string): boolean => {
    return WHITELISTED_EMAILS.includes(email);
}

export const isEmailValid = (email: string): boolean => {
    // Regular expression for basic email format validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};