import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import { LicenseInfo } from '@mui/x-license';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const licenseKey: string = '9d01e3804b94c1ed71baa9007b2c9ad7Tz03NTIzNSxFPTE3MjcwMDk4ODMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=';
LicenseInfo.setLicenseKey(licenseKey);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);