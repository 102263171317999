import React from 'react';
import SocialPost from '../interfaces/SocialPost';

interface SocialPostComponentProps {
  socialPosts?: SocialPost;
}

const SocialPostComponent: React.FC<SocialPostComponentProps> = ({ socialPosts }) => {
    return (
        <ul>
            <li><strong>LinkedIn:</strong> {socialPosts?.LinkedIn}</li>
            <li><strong>Facebook:</strong> {socialPosts?.Facebook}</li>
            <li><strong>Twitter:</strong> {socialPosts?.Twitter}</li>
        </ul>
    );
};

export default SocialPostComponent;
