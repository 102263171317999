import React, { useEffect, useState } from 'react';
import { Container, Box, Typography, TextField, Button } from '@mui/material';
import * as Passwordless from '@passwordlessdev/passwordless-client';
import { VERIFY_SIGNIN_ENDPOINT, PASSWORDLESS_API_KEY, PASSWORDLESS_API_URL } from '../config/PasswordlessOptions';
import axios from 'axios';
import { isEmailValid, isEmailWhiteListed } from '../config/WhitelistedEmails';
import { useSearchParams } from 'react-router-dom';

interface LoginPageProps {
    handleAuthentication: (status: boolean) => void;
}
const passwordlessClient: Passwordless.Client = new Passwordless.Client({
    apiKey: PASSWORDLESS_API_KEY,
    apiUrl: PASSWORDLESS_API_URL
});

const LoginPage: React.FC<LoginPageProps> = ({ handleAuthentication }) => {
    const [email, setEmail] = useState<string>('');
    
    // if coming in from registration page and search param set
    const [searchParams] = useSearchParams();
    
    useEffect(() => {
        const emailParam = searchParams.get('email');
        if (emailParam) {
            setEmail(emailParam);
            searchParams.delete('email');
        }
    }, [searchParams]);

    const handleLogin = async (e: any) => {
        e.preventDefault();

        if (!email) {
            alert('You must fill out an email address prior to logging in');
            return false;
        }

        if (!isEmailValid(email)) {
            alert('You must enter a valid email');
            return false;
        }

        if (!isEmailWhiteListed(email)) {
            alert('You must enter a whitelisted email. Please contact an administrator if you are having problems.');
            return false;
        }

        try {
            const { token } = await passwordlessClient.signinWithAlias(email);
            if(!token) {
                throw new Error('Token is null'); 
            }
            // Call backend to verify token
            const verifiedUserResponse = await axios.get(VERIFY_SIGNIN_ENDPOINT, {
                params: { token: token }
            });

            if (verifiedUserResponse.status === 200) {
                console.log(verifiedUserResponse);
                localStorage.setItem('userId', verifiedUserResponse.data.userId);
                localStorage.setItem('nickname', verifiedUserResponse.data.nickname);
                localStorage.setItem('expiresAt', verifiedUserResponse.data.expiresAt);
                localStorage.setItem('authType', verifiedUserResponse.data.type);
                localStorage.setItem('tokenId', verifiedUserResponse.data.tokenId);
                if(token) {
                    localStorage.setItem('authToken', token);
                }
                handleAuthentication(true);
            }
            else {
                console.log('There was an issue logging in');
                handleAuthentication(false);
            }
        } catch(error: any) {
            console.error("Error logging in:");
            console.error(error);
            handleAuthentication(false);
        }
    };

    return (
        <Container component="main" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
            <Box sx={{ width: '100%', maxWidth: '400px' }}>
                <Typography variant="h5" align="center" gutterBottom>
                    Login
                </Typography>
                <Typography variant="body2" align="center" color="textSecondary">
                    Please enter your email address to login
                </Typography>
                <TextField
                    label="Email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                    margin="normal"
                    required
                />
                <Button variant="contained" onClick={handleLogin} fullWidth>
                    Login
                </Button>
            </Box>
        </Container>
    );
};

export default LoginPage;
