import React, { useCallback, useEffect, useState } from 'react';
import axios, { AxiosError, AxiosResponse } from 'axios';
import './App.css';
import ListingPage from './components/ListingPage';
import AnalyzedItem from './interfaces/AnalyzedItem';
import { Container, AppBar, Toolbar, Typography, Box, CssBaseline, CircularProgress } from '@mui/material';
import { Navigate, Route, Routes } from 'react-router-dom';
import ViewPage from './components/ViewPage';
import EditPage from './components/EditPage';
import SpecialtiesAndTopics from './interfaces/SpecialtiesAndTopics';
import RegisterPage from './components/RegisterPage';
import LoginPage from './components/LoginPage';

// AI Content Analyzer Azure Backend
const getAnalyzedItemsEndpoint: string = 'https://aicontentanalyzer2.azurewebsites.net/api/analyzeditems?code=4D9O1TISrlGM9CSC0OcHtcT6q50PYg-woc6xhrGVyxODAzFuY51njw==&bodyText=false';
const getSpecialtiesAndTopicsEndpoint: string = 'https://aicontentanalyzer2.azurewebsites.net/api/GetSpecialtiesAndTopics?code=9Aj09Jgvg6E04CO2-8BFslx2yDZBV6pp7ZxRU7r2qCKfAzFuY02rVw==';

const App: React.FC = () => {
  //const navigate = useNavigate();
  const [analyzedItems, setAnalyzedItems] = useState<AnalyzedItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [specialties, setSpecialties] = useState<string[]>([]);
  const [specialtyTopicsMap, setSpecialtyTopicsMap] = useState<{ [key: string]: string[] }>({});
  //const [authenticated, setAuthenticated] = useState<boolean>(false);
  // Initialize authenticated based on token in local storage
  const [authenticated, setAuthenticated] = useState<boolean>(localStorage.getItem('authToken') !== null); 

  const fetchData = useCallback(async () => {
    try {
      const getAnalyzedItemsResponse: AxiosResponse<AnalyzedItem[], any> = await axios.get<AnalyzedItem[]>(getAnalyzedItemsEndpoint);
      setAnalyzedItems(getAnalyzedItemsResponse.data);

      const getSpecialtiesAndTopicsResponse: AxiosResponse<SpecialtiesAndTopics[], any> = await axios.get<SpecialtiesAndTopics[]>(getSpecialtiesAndTopicsEndpoint);

      const specialties: string[] = getSpecialtiesAndTopicsResponse.data.map((specialtyAndTopics: SpecialtiesAndTopics) => specialtyAndTopics.Specialty);
      setSpecialties(specialties);

      const specialtyTopicsMap: { [key: string]: string[] } = {};
      getSpecialtiesAndTopicsResponse.data.forEach((item: SpecialtiesAndTopics) => {
        const { Specialty, TopicsConditions } = item;
        const uniqueTopics: string[] = Array.from(new Set(TopicsConditions));
        specialtyTopicsMap[Specialty] = uniqueTopics;
      });
      setSpecialtyTopicsMap(specialtyTopicsMap);

      setLoading(false);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        console.error('Axios error:', axiosError.message);
        console.error('Axios error response:', axiosError.response?.data);
      } else {
        console.error('Error fetching data:', error);
      }
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if(authenticated) {
      fetchData();
    }
  }, [authenticated, fetchData]);

  const handleAuthentication = (status: boolean) => {
    setAuthenticated(status);
  };

  return (
    <>
      <CssBaseline />
      <AppBar position="static">
        <Container maxWidth="xl">
          <Toolbar>
            <img src="/logo262x64.png" alt="Content Boost AI Logo" style={{ height: '48px' }} />
          </Toolbar>
        </Container>
      </AppBar>

      <Container component="main" sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }} maxWidth="xl">
        <Box sx={{ flex: 1, p: 3 }}>
        {
            authenticated ? (
              loading ? (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                  <CircularProgress />
                </Box>
              ) : (
                <Routes>
                  <Route path="/" element={<ListingPage analyzedItems={analyzedItems} />} />
                  <Route path="/view/:id" element={<ViewPage />} />
                  <Route path="/edit/:id" element={<EditPage specialties={specialties} specialtyTopicsMap={specialtyTopicsMap} />} />
                  <Route path="*" element={<Navigate to="/" replace />} /> {/* Redirect to home if route doesn't match */}
                </Routes>
              )
            ) : (
              <Routes>
                <Route path="/" element={<LoginPage handleAuthentication={handleAuthentication} />} />
                <Route path="/register" element={<RegisterPage />} />
              </Routes>
            )
          }
        </Box>
      </Container>

      <footer>
        <Container maxWidth="xl">
          <Typography variant="body2" color="text.secondary" align="center">
            Copyright &copy; {new Date().getFullYear()} Content Boost AI. Powered by Conexiant. All rights reserved. Reproduction in whole or in part without permission is prohibited.
          </Typography>
        </Container>
      </footer>
    </>
  );
}

export default App;
